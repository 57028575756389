/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var $homeLink = '<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-00"><a href="/">Home</a></li>';
        $(".menu-item-59").before($homeLink);
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        console.log('%cA Rebuild Group Website', 'color:666;');
        console.log('%chttp://rebuild.group', 'color:orange;');

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        /*jQuery(window).scroll(function(){
        var fromtop = jQuery(window).scrollTop();
          jQuery(" your element ").css({"background-position-y": fromtop+"px"});
        });*/

        AOS.init();

        /*
        var logoOffset = $('#logo_wrapper').offset().top();
        
        $(window).scroll(function(){
          var nav     = $('.banner'),
              logo    = $('#logo'),
              scroll  = $(window).scrollTop();

          if (scroll >= logoOffset) {
            nav.addClass('fixed');
            logo.addClass('zoomInDown');
            logo.css('display', 'block'); 
          }
          else {
            nav.removeClass('fixed');
            logo.css('display', 'none');
          } 
        });
        */

        $('#slide__heading').attr('data-aos', 'fade-up').attr('data-aos-anchor-placement', 'center-center');
        $('#slide__button').attr('data-aos', 'fade-up').attr('data-aos-anchor-placement', 'center-center');

        $(window).scroll(function(){
          var pos     = 200,
              nav     = $('.banner'),
              scroll  = $(window).scrollTop();
            
          //if ( scroll >= 70 && windowWidth > 767 ) {
          if ( scroll >= pos ) {
            nav.addClass('fixMe');
          } else {
            nav.removeClass('fixMe');
          }
        });

        // Scroll Down
        var scroll_icon = '<div class="scroll-down"><span class="fa-stack"><i class="fa fa-circle-thin fa-stack-2x"></i><i class="fa fa-long-arrow-down fa-stack-1x"></i></span></div>';
        $('#main_start').append(scroll_icon);

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'about': {
      init: function() {
        //nav = $('.banner');
        //nav.addClass('fixed');
        // https://developers.google.com/youtube/iframe_api_reference


        // // Inject YouTube API script
        // var tag = document.createElement('script');
        // tag.src = "//www.youtube.com/iframe_api";
        // var firstScriptTag = document.getElementsByTagName('script')[0];
        // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // // global variable for the player
        // var player;

        // // this function gets called when API is ready to use
        // function onYouTubeIframeAPIReady() {
        //   console.log('video ready');
        //   // create the global player from the specific iframe (#video)
        //   player = new YT.Player('about__video', {
        //     events: {
        //       // call this function when player is ready to use
        //       'onReady': onPlayerReady
        //     }
        //   });
        // }

        // function onPlayerReady(event) {
          
        //   // bind events
        //   var playButton = document.getElementById("play");
        //   playButton.addEventListener("click", function() {
        //     player.playVideo();
        //   });
          
        // }

        // Toggle Video
        $('#play').click(function(e) {
          $(this).hide();
          $('#collapseTwo').show();
          $('#about__video')[0].src += "&autoplay=1";
          e.preventDefault();
          // console.log('clicked');
        });
      }
    },
    'expertise': {
      init: function() {
        //nav = $('.banner');
        //nav.addClass('fixed');
      }
    },
    'results': {
      init: function() {
        /*
        var isMobile = window.matchMedia("only screen and (max-width: 760px)");

        if (isMobile.matches) {
          $('#copy').addClass('mobile');
          $('.cases__wrapper').addClass('mobile');
          $('#fullscreen').removeAttr('id');
        }
        */

        $('#fullscreen').fullpage({
          verticalCentered: true,
          navigation: true,
          paddingTop: 10,
          easing: 'easeInOut',
          scrollingSpeed: 1000,
          autoScrolling: true,
          
          onLeave: function(index){
            $('#'+index).fadeOut(150);
            //console.log("onLeave--" + "index: " + index);
          },
          afterLoad: function(anchorLink, index){
            $('#'+index).fadeIn(400);
            //console.log("afterLoad--" + " index: " + index );
          }
        });

        $('#next').click(function(e){
          e.preventDefault();
          $.fn.fullpage.moveSectionDown();
        });

        $('#prev').click(function(e){
          e.preventDefault();
          $.fn.fullpage.moveSectionUp();
        });
      }
    },
    'single_cases': {
      init: function() {

        $('#fullscreen').fullpage({
          verticalCentered: true,
          //navigation: true,
          //paddingTop: 10,
          easing: 'easeInOut',
          scrollingSpeed: 1000,
          autoScrolling: true,
          
          onLeave: function(index){
            $('#'+index).fadeOut(150);
            $('#partTitle').fadeOut(150).text('');
            //console.log("onLeave--" + "index: " + index);
          },
          afterLoad: function(anchorLink, index){
            $('#'+index).fadeIn(400);
            var $part = $('#'+index).data("part");
            $("#partTitle").text($part).fadeIn(400);
            //console.log("afterLoad--" + " index: " + index );
          }
        });

        $('#next').click(function(e){
          e.preventDefault();
          $.fn.fullpage.moveSectionDown();
        });

        $('#prev').click(function(e){
          e.preventDefault();
          $.fn.fullpage.moveSectionUp();
        });

        /*
        var isMobile = window.matchMedia("only screen and (max-width: 760px)");

        if (isMobile.matches) {
          $('#copy').addClass('mobile');
          $('.cases__wrapper').addClass('mobile');
          $('#fullscreen').removeAttr('id');
        }
        */
        /*
        $('#fullscreen').fullpage({
          anchors:['challenge', 'approach', 'results'],
          //menu: '#caseMenu',
          verticalCentered: true,
          //navigation: true,
          paddingTop: 10,
          easing: 'easeInOut',
          scrollingSpeed: 1000,
          autoScrolling: true,
          
          onLeave: function(index){
            $('#'+index).fadeOut(150);
            //console.log("onLeave--" + "index: " + index);
          },
          afterLoad: function(anchorLink, index){
            $('#'+index).fadeIn(400);
            //console.log("afterLoad--" + " index: " + index );
          }
        });
        */

        /*
        $('#next').click(function(e){
          e.preventDefault();
          $.fn.fullpage.moveSectionDown();
        });

        $('#prev').click(function(e){
          e.preventDefault();
          $.fn.fullpage.moveSectionUp();
        });
        */

      }
    },
    'blog': {
      init: function() {
        // BLOG FILTERING
        $('#blog_filter').submit(function(){
          var filter = $('#blog_filter');
          $.ajax({
            url:filter.attr('action'),
            data:filter.serialize(), // form data
            type:filter.attr('method'), // POST

            beforeSend:function(xhr){
              filter.find('button').text('Processing...'); // changing the button label
              $('.article__wrap article').fadeOut(); // insert data
              loader = '<div class="loading"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></div>';
              $('.article__wrap article').append(loader);
            },

            success:function(data){
              filter.find('button').text('Apply filter'); // changing the button label back
              $('.article__wrap article').hide(); // insert data
              $('#blog_wrap').html(data); // insert data
              $('.article__wrap article').fadeIn(); // insert data
            }

          });
          return false;
        });
      }
    },
    'contact': {
      init: function() {

        // When the window has finished loading create our google map below
        google.maps.event.addDomListener(window, 'load', init);
    
        function init() {
            // Basic options for a simple Google Map
            // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
            var mapOptions = {
                // How zoomed in you want the map to start at (always required)
                zoom: 13,

                mapTypeControl: false,
                scaleControl: true,
                scrollwheel: false,

                // The latitude and longitude to center the map (always required)
                center: new google.maps.LatLng(42.511986, -83.327343), // LOCATION

                // How you would like to style the map. 
                // This is where you would paste any style found on Snazzy Maps.
                styles: [
                  {
                      "featureType": "all",
                      "elementType": "labels.text.fill",
                      "stylers": [
                          {
                              "visibility": "on"
                          },
                          {
                              "color": "#ffffff"
                          }
                      ]
                  },
                  {
                      "featureType": "all",
                      "elementType": "labels.text.stroke",
                      "stylers": [
                          {
                              "color": "#ffffff"
                          },
                          {
                              "visibility": "on"
                          },
                          {
                              "weight": 0.9
                          }
                      ]
                  },
                  {
                      "featureType": "all",
                      "elementType": "labels.icon",
                      "stylers": [
                          {
                              "visibility": "off"
                          }
                      ]
                  },
                  {
                      "featureType": "administrative",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#65996a"
                          },
                          {
                              "weight": 0.7
                          }
                      ]
                  },
                  {
                      "featureType": "landscape",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#65996a"
                          }
                      ]
                  },
                  {
                      "featureType": "poi",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#65996a"
                          }
                      ]
                  },
                  {
                      "featureType": "poi",
                      "elementType": "labels",
                      "stylers": [
                          {
                              "visibility": "simplified"
                          }
                      ]
                  },
                  {
                      "featureType": "road.highway",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#fd7400"
                          }
                      ]
                  },
                  {
                      "featureType": "road.highway",
                      "elementType": "labels.icon",
                      "stylers": [
                          {
                              "visibility": "on"
                          }
                      ]
                  },
                  {
                      "featureType": "road.arterial",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#65996a"
                          },
                          {
                              "lightness": -20
                          }
                      ]
                  },
                  {
                      "featureType": "road.local",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#65996a"
                          },
                          {
                              "lightness": -17
                          }
                      ]
                  },
                  {
                      "featureType": "transit",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#65996a"
                          },
                          {
                              "lightness": -10
                          }
                      ]
                  },
                  {
                      "featureType": "water",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#457186"
                          }
                      ]
                  }
              ]
            };

            // Get the HTML DOM element that will contain your map 
            // We are using a div with id="map" seen below in the <body>
            var mapElement = document.getElementById('googleMap');

            // Create the Google Map using our element and options defined above
            var map = new google.maps.Map(mapElement, mapOptions);

            // Let's also add a marker while we're at it
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(42.511986, -83.327343),
                map: map,
                title: 'Tanner Freidman'
            });

            var contentString = 
              '<div id="content">'+
                //'<div id="siteNotice">'+'</div>'+
                '<h2 class="firstHeading">Tanner Friedman</h2>'+
                '<div id="bodyContent">'+
                  '<p>31500 Northwestern Highway,<br />' +
                  'Suite 175<br />' +
                  'Farmington Hills, MI 48334</p>' +
                  '<p><strong>(248) 626-0006</strong></p>'+
                  '<p><a href="https://goo.gl/maps/U4t37BSxF2fxBo3z6" target="_blank">Get Directions</a></p>'+
                '</div>'+
              '</div>';

            var infowindow = new google.maps.InfoWindow({
              content: contentString
            });

            marker.addListener('click', function() {
              infowindow.open(map, marker);
            });

        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
